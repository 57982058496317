
.cl-yt-solution-video-header-divider {
    background-color: rgba(170, 170, 170, 0.3);
    margin-top: 0;
    width: 100%;
    margin-bottom: 4px;
    height: 2px;
}

.cl-solution-list-header {
    /* background-color: rgb(199, 251, 177); */
    background-color:  var(--theme-color-v2-c0-rgb);
    color:  var(--theme-color-v2-c0-rgb);
    border-radius: 4px;
    cursor: pointer;
    padding: 0.25rem;
    margin-top: 0;
    margin-bottom: 0;
}

.cl-solution-list-header:hover {
    /* background-color: rgb(241, 251, 172); */
    background-color:  var(--theme-color-v2-c1-rgb);
}

.cl-solution-list-btn {
    border: 2px solid white;
    color: white;
    padding: 0 0.5rem;
}

/* Solution Text List */
.cl-solution-text-header-divider {
    background-color: rgba(170, 170, 170, 0.3);
    margin-top: 0;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 4px;
    height: 2px;
}

/* 화면 전체를 감싸는 박스 */
.cl-study-solution-list-wrap {
    position:relative;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
}

/* 라디오 인풋을 이용했으며 인풋에 라벨을 이어 버튼으로 사용하므로 
인풋 자체는 보여질 필요가 없음, 인풋 자체는 보이지 않게 가림 */
.cl-study-solution-list-wrap input.button {
    display:none;
}

/* 라디오 인풋에 체크가 되면, 인접한 .contents 상자를 보이게 함 */
.cl-study-solution-list-wrap input[type=radio]:checked + .contents { 
    visibility: visible;
    max-height:500px;
    -webkit-transform: scale(1,1);
    -ms-transform: scale(1,1);
    transform:scale(1,1);
    opacity:1;
}
/* 라디오 인풋, 모두 닫는 버튼 */
.cl-study-solution-list-wrap .closer {
    position:absolute;
    top:0;
    right:0;
    display:inline-block;
    width:50px;
    height:50px;
    line-height:50px;
    background:#ADB4B0;
    text-align:center;
    font-family:"Arial Black";
    font-size:25px;
    color:#fff;
    -moz-box-shadow:1px 1px 3px 5px rgba(0,0,0,0.02);
    -webkit-box-shadow:1px 1px 3px 5px rgba(0,0,0,0.02);
    box-shadow:1px 1px 3px 5px rgba(0,0,0,0.02);
    cursor:pointer;
}

/* 닫기 버튼 애니메이션 효과, 삭제시 기능상 무방함 */
.cl-study-solution-list-wrap input[type=radio].close:checked + .closer {
    transform:translate(0,-100%);
    -webkit-transition:transform .2s ease;
    transition:transform .2s ease;
}

.cl-study-solution-list-wrap input[type=radio].close:not(:checked) + .closer {
    transform:translate(0,0);
    -webkit-transition:transform .2s ease;
    transition:transform .2s ease;
}

/* 메뉴 버튼 디자인 */
.cl-study-solution-list-wrap  .label {
    /* height:50px;
    line-height:50px; */
    width:100%;
    display:inline-block;
    /* overflow:hidden; */
    background:#94DCB9;
    cursor:pointer;
}
/*  메뉴 버튼에 들어갈 아이콘을 100px * 100px, 투명한 PNG 로 저장하여 아래의 경로에 연동해주면 됨 
    위쪽 코드 .label.a 부터 맨 아래코드 .label.e 까지가 메뉴버튼 오른쪽-> 왼쪽 순서 */
.cl-study-solution-list-wrap  .label.a {
    background:#2E97B7;
}

.cl-study-solution-list-wrap  .label.b {
    background:#32B9BE;
}
.cl-study-solution-list-wrap  .label.c {
    background:#5BCEBF;
}
.cl-study-solution-list-wrap  .label.d {
    background:#74DCB9;
}
.cl-study-solution-list-wrap  .label.e {
    background:#94DCB9;
}

.cl-study-solution-list-wrap  .label .rotate {
    display:inline-block;
}

.cl-study-solution-list-wrap  .label .rotate p {
    position:relative;
    font-size:1.5em;
    white-space: nowrap;
    left:40%;
    font-family: "arial";
    font-weight:600;
    color:#fff;
}

/* 콘텐츠 본문 */
.cl-study-solution-list-wrap  .contents {
    position:relative;
    visibility: collapse;
    /* float:left; */
    height:auto;
    width:100%;
    -webkit-transform: scale(1,0);
    -ms-transform: scale(1,0);
    transform:scale(1,0);
    -ms-transform-origin:top;    
    -webkit-transform-origin:top;  
    transform-origin:top;  
    max-height:0;
    -webkit-transition:transform 0.2s ease-out, opacity 0.2s ease-out, max-height 0.1s ease-out;
    transition:transform 0.2s ease-out, opacity 0.2s ease-out, max-height 0.1s ease-out;
    opacity:0;
    z-index:-1;
    /* overflow:hidden;
    background: -moz-linear-gradient(-45deg, rgba(46,151,183,0.4) 0%, rgba(164,220,185,0.9) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(46,151,183,0.4) 0%, rgba(164,220,185,0.9) 100%);
    background: linear-gradient(135deg, rgba(46,151,183,0.4) 0%, rgba(164,220,185,0.9) 100%); */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66c0c60a', endColorstr='#59007c33',GradientType=1 ); */
}

/* .inner {
    display: table;
    width:340px;
    height:100%;
    margin:30px auto;
    -moz-box-shadow:0px 0px 3px 3px rgba(0,0,0,0.05);
    -webkit-box-shadow:0px 0px 3px 3px rgba(0,0,0,0.05);
    box-shadow:0px 0px 3px 3px rgba(0,0,0,0.05);
}
.description {
    display:table-cell;
    vertical-align: middle;
    padding: 5%;
    background: rgba(0,0,0,0.04);
}
.description h3 {
    width:100%;
    text-overflow:ellipsis;
    overflow:hidden;
    font-size:30px;
    color:#37a4ab;
    line-height:1;
    margin:0;
    text-shadow: rgb(36, 127, 132) 2px 2px 0px;
    margin-bottom:10px;
}
.description h3::first-letter {
    font-size:50px;
    color:#0f848c;
}
.description p {
    font-size:19px;
    line-height:1.7;
    font-weight:600;
    color:#4e6b61;
    padding-left: 20px;
    overflow:auto;
    letter-spacing:-1px;
} */
