.mtm-player-testum {
  display: none;
}

.mtm-player-testum.activate {
  display: block;
}

@media screen and (min-width: 1025px) {
  .mtm-player-testum.activate {
    display: flex;
    flex-direction: column;
  }
}

.mtm-player-testum > .mtm-player-testum-indicator {
  display: flex;
  justify-content: center;
}

.mtm-player-testum > .mtm-player-testum-indicator > .mtm-player-testum-indicator-flexbox {
  width: 70%;
}

.testum-grading-button {
  padding: 6px 1.5rem;
  margin: 0 8px;
  border-radius: 20px;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%),
    0 4px 15px 0 rgb(0 0 0 / 15%);
  outline: 0px;
  user-select: none;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;

  color: #fff;
  background: var(--theme-color-v2-c0-rgb);
  border: 2px solid var(--theme-color-v2-c0-rgb);

  z-index: 10;
  position: absolute;
  bottom: 40px;
  /* right: 0px; */
  right: 16px;
  font-weight: 600;
  font-size: 14px;
  display: block;
}

.testum-grading-button:not(:disabled) {
  cursor: pointer;
}

.testum-grading-button:hover:not(:disabled) {
  cursor: pointer;
  color: #fff;
  background: var(--theme-color-v2-c1-rgb);
  border: 1px solid var(--theme-color-v2-c1-rgb);
}

.testum-grading-button:active:not(:disabled) {
  color: #fff;
  background-color: white;
  color: var(--theme-color-v2-c1-rgb);
  transform: translateY(1px);
  outline: 0px;
}

.grading-button-wrapper {
  /* display: flex; */
  align-self: flex-end;
}

@media screen and (max-width: 1024px) {
  .grading-button-wrapper {
    position: sticky;
    z-index: 72;
    bottom: 0.5rem;
    right: 0.5rem;
    align-self: flex-end;
    /* width: 100vw;
    background-color: #fff;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center; */
  }

  .testum-grading-button {
    position: sticky;
    margin: 0;
    /* top: 0; */
    bottom: 8px;
    right: 0px;
    /*  */
    align-self: flex-end;
  }
}

@media screen and (max-width: 480px) {
  /* 오히려 불편할 수도 있을거 같다. */
  /* 일단 모바일에서는, 최하단에 고정하자. */

  .grading-button-wrapper {
    position: sticky;
    z-index: 8;
    bottom: 0;
    align-self: center;
    flex: 0 0 auto;
    width: 100%;
    background-color: #fff;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
  }

  .testum-grading-button {
    position: sticky;
    /* background-color: #1163ff; */
    border-radius: 0.75rem;
    font-size: 20px;
    font-weight: 500;
    padding: 0.75rem 1.5rem;

    width: 80%;
    border: none;
    align-self: center;
    /* bottom: 16px; */
  }
  /* .testum-grading-button {
    position: static;
    border-radius: 0.75rem;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;

    width: 80%;
    border: none;
    align-self: center;
    bottom: 16px;
  } */
}


