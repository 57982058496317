.mtu-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  /* line-height: 1.5; */
  line-height: 1.57;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #fff;
  background-image: none;
  border-width: 1px;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

/* wrapper */
.mtu-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0,0,0,.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all .2s;
}

.mtu-input:hover {
  border-color: #4096ff;
}

.mtu-input:active {
  border-color: #1677ff;
}

.mtu-input:disabled {
  background-color: rgba(0, 0, 0, 0.04);
  color: 0, 0, 0, 0.25;
  cursor: not-allowed;
}

.mtu-input:placeholder-shown {
  text-overflow: ellipsis;
}

/* Size */
.mtu-input-large,
.mtu-input-affix-wrapper-large {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
}

.mtu-input-small,
.mtu-input-affix-wrapper-small {
  padding: 0 7px;
  border-radius: 4px;
}



.mtu-input-affix-wrapper:hover {
  border-color: #4096ff;
}

.mtu-input-affix-wrapper:focus {
  z-index: 1;
  border-color: #1677ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}

.mtu-input-affix-wrapper:not(:disabled):hover {
  z-index: 1;
}

.mtu-input-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}

.mtu-input-affix-wrapper > .mtu-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

/* prefix */
.mtu-input-affix-wrapper > .mtu-input-prefix {
  display: flex;
  flex: none;
  align-items: center;
  margin-inline-end: 4px;
}

/* prefix */
.mtu-input-affix-wrapper > .mtu-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
  margin-inline-end: 4px;
}