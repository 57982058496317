.mtu-card {
  box-sizing:border-box;
  margin:0;
  padding:0;
  color:rgba(0, 0, 0, 0.88);
  font-size:14px;
  line-height:1.5714285714285714;
  list-style:none;
  font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position:relative;
  background:#ffffff;
  border-radius:8px;
}

.mtu-card-bordered {
  border: 1px solid #f0f0f0;
}

.mtu-card.mtu-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.2s, border-color 0.2s;
}

/* head */
.mtu-card-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 56px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0 ;
}

.mtu-card-type-inner > .mtu-card-head {
  padding: 0 24px;
  background: rgba(0, 0, 0, 0.02);
}

.mtu-card-head-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

/* body */
.mtu-card-body {
  box-sizing: border-box;
  padding: 24px;
  border-radius: 0 0 8px 8px;
}

.mtu-card-body::before{
  display: table;
  content: "";
}
.mtu-card-body::after{
  display: table;
  clear: both;
  content: "";
}

/* title */
.mtu-card-head-title {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mtu-card-type-inner > .mtu-card-head > .mtu-card-head-title {
  font-size: 14px;
}

/* cover */
.mtu-card-cover {
  box-sizing: border-box;
  margin-top: -1px;
  margin-inline-start: -1px;
  margin-inline-end: -1px;
}

.mtu-card-cover > img {
  display: block;
  width: 100%;
  border-radius: 8px 8px 0 0;
}