.mtm-player-lesson-title-container {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mtm-player-lesson-title-icon {
  font-weight: 900;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.mtm-player-lesson-title-icon:before {
  content: "\f167"
}