/*
```
bootstrap styles
bootstrap 속성 중 몇가지가, 필요하다.
임의로 지정.
```
 */

.st-course-builder.study-preview .row.d-flex.justify-content-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.st-course-builder.study-preview .d-flex {
  display: flex;
}

.st-course-builder.study-preview .justify-content-center {
  justify-content: center;
}

/* .st-course-builder.study-preview .row>* {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
} */

@media (min-width: 1200px) {
  .st-course-builder.study-preview .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}
@media (min-width: 992px) {
  .st-course-builder.study-preview .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}
@media (min-width: 768px) {
  .st-course-builder.study-preview .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
/* @media (min-width: 1200px) {
  .study-preview .swiper-container {
      flex: 0 0 auto;
      width: 66.66666667%;
  }
}
@media (min-width: 992px) {
  .study-preview .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
  }
}
@media (min-width: 768px) {
  .study-preview .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
  }
}
.col-12 {
    flex: 0 0 auto;
    width: 100%;
} */

.study-preview .mtm-plyr {
  height: auto;
}
/*
```
swiper too big width
https://github.com/nolimits4web/swiper/issues/3599#issuecomment-1290283431
```

 */
.st-course-builder.study-preview {
  display: grid;
  justify-content: stretch;

  flex: 1 1 auto;
  transition:
    margin-left 0.2s ease 0s,
    margin-right 0.2s ease 0s;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.study-preview .mtm-plyr {
  max-height: none;
}

.st-course-builder.study-preview .swiper {
  max-width: 100%;
}

@media (max-width: 640px) {
  .mtm-study-answer-choice-number {
    width: 30px;
    height: 30px;
    margin: 4px;
  }

  .swiper-slide {
    font-size: 16px;
  }

  #id-mtm-question-swiper-1 .swiper-container {
    /* width: 100% !important; */
    /* height: 500px !important; */
  }
}

/* https://github.com/nolimits4web/swiper/issues/3599 */
.swiper-container {
  min-width: 0;
  width: 100%;
  display: grid;
  overflow: hidden;
}

/* @media (min-width: 1024px) {
  .swiper-container {
    width: 1000px !important;
  }
} */
.swiper-container .swiper-wrapper {
  min-width: 0;
}

.study-preview .mtm-testum-card-normal-normal figure {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .study-preview .grading-button-wrapper {
    position: sticky;
    z-index: 72;
    bottom: 0.5rem;
    right: 0.5rem;
    align-self: flex-end;
    /* width: 100vw;
    background-color: #fff;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center; */
  }

  .study-preview .testum-grading-button {
    position: sticky;
    margin: 0;
    /* top: 0; */
    bottom: 8px;
    right: 0px;
    /*  */
    align-self: flex-end;
  }
}

.study-preview p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.study-preview figure {
  margin: 0 0 1rem;
}

.study-preview table {
  caption-side: bottom;
  border-collapse: collapse;
}

.study-preview tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
