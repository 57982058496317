.st-course-builder {
  flex: 1 1 auto;
  transition: margin-left 0.2s ease 0s, margin-right 0.2s ease 0s;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
}

.st-course-builder>div {
  max-width: 90%;
  max-width: 100%;
  height: 100%;
  min-width: 70%;
  margin: 0 auto;
  /* overflow: auto; */
}



@media (max-width: 769px) {
  .st-course-builder {
    min-height: calc(1vw * 64);
    /* display: flex;
    flex-direction: column; */
    transition: height 0.3s ease 0s;
    margin-bottom: 8px;
  }
}

@media (max-width: 640px) {
  .mtm-study-answer-choice-number {
    width:30px;
    height:30px;
    margin:4px;
  }

  .study-preview .swiper-slide {
    font-size: 16px;
  }

  .study-preview #id-mtm-question-swiper-1 .swiper-container {
    /* width: 100% !important;
    height: 500px !important; */
    
  }
}


