#menuToggle {
  display: none;
}

@media (max-width: 820px){
  #menuToggle {
    display: block;
  }
}
 
 #menuToggle
 {
   position: relative;
   transform: translate(0, 3px);
   z-index: 1;
   
   -webkit-user-select: none;
   user-select: none;
 }

 
 #menuToggle input
 {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: -7px;
   left: -5px;
   
   cursor: pointer;
   
   opacity: 0; /* hide this */
   z-index: 2; /* and place it over the hamburger */
   
   -webkit-touch-callout: none;
 }
 
 /*
  * Just a quick hamburger
  */
 #menuToggle span
 {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative;
   
   background: white;
   border-radius: 3px;
   
   z-index: 1;
   
   transform-origin: 4px 0px;
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
   transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 
 /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
 #menuToggle input:checked ~ span
 {
   opacity: 1;
   transform: rotate(45deg) translate(-2px, -1px);
   background: white;
 }
 
 /*
  * But let's hide the middle one.
  */
 #menuToggle input:checked ~ span:nth-last-child(3)
 {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 /*
  * Ohyeah and the last one should go the other direction
  */
 #menuToggle input:checked ~ span:nth-last-child(2)
 {
   transform: rotate(-45deg) translate(0, -1px);
 }
 
 /*
  * Make this absolute positioned
  * at the top left of the screen
  */
  #menuToggle #menu
 {
   width: 200px;
   height: 100vh;
   padding: 1.5rem;
   padding-top: 72px;
   background: #F17272;
   position: absolute;
   top: -30px;
   right: -20px;
   transform-origin: 0% 0%;
  transform: translate(120%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 }

 #menuToggle input:checked ~ #menu {
   transform: none;
 }
 
 #menu li
 {
   display: flex;
   padding: 10px;
   font-size: 12px;
   color: white;
   cursor: pointer;
 }

 #menu li:hover {
  background-color: rgb(0, 0, 0, 0.3);
 }
 
 #menu li p {
  padding-left: 8px;
 }