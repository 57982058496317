.mtm-elapse-timer{
    /* color: #007bff;
    border-color: #007bff; */
    /* color: rgb(243,174,34);
    border-color: rgb(243,174,34); */
    color : var(--theme-color-v2-c0-rgb);
    border: 2px solid var(--theme-color-v2-c0-rgb);
}


.mtm-elapse-timer {
    /* position: absolute;
    top : 30px;
    left : 50%;
    transform: translateX(-50%);
    z-index: 10; */
    
    display: inline-block;
    /* color: #212529; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    /* border: 1px solid transparent; */
    padding: 0.375rem 0.75rem;

    font-size: 0.8em;
    font-weight: 700;

    line-height: 1.5;
    border-radius: 40px;

    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    /* border: transparent; */
    outline: 0px;

    margin-left: 0;
    padding: 0.25rem 1rem;
    
    /* display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px; */
}

.mtm-elapse-timer:hover{
    color : white;
    background-color: var(--theme-color-v2-c0-rgb);
}

.mtm-elapse-timer:active{
    color : white;
    background-color: var(--theme-color-v2-c0-rgb);
}
