.mtm-player-testum-indicator-flexbox {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mtm-player-testum-indicator-swiper {
    width: 100%;
    overflow-x: hidden;
    height: 30px;
    border-radius: 20px;
}

.mtm-player-testum-indicator .swiper-slide{
    
    font-size: 18px;
	background-color: transparent;
    background: transparent;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
    /* border-radius: 100%; */
}

.mtm-player-testum-thumb {
    box-sizing: border-box;
    width: 30px;
    height: 30px;

    /* color: #fff;
    background: #1c98f0; */
    color : rgb(190, 208, 232);
    background: #fff;

    border-radius: 100%;
    border: 2px solid rgb(190, 208, 232);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
}

.mtm-player-testum-thumb.answer{
    background: var(--theme-color-v2-c3-rgb);
}

.mtm-player-testum-thumb.active{

    /* color : black; */
    color: var(--theme-color-v2-c1-rgb);
    font-weight: 600;
    /* border: 3px solid rgba(46, 107, 174); */
    border: 3px solid var(--theme-color-v2-c1-rgb);
}

.mtm-player-testum-thumb.answered{
    background-color: yellow;
}

.mtm-player-testum-indicator .swiper-container{
    /* background-color: var(--theme-color-deeplight); */
    /* background-color: rgba(var(--theme-color-v2-c1),0.2); */
    background: linear-gradient(to right, rgba(var(--theme-color-v2-c1),0), rgba(var(--theme-color-v2-c1),0.5) 50%, rgba(var(--theme-color-v2-c1),0));

}