.mtm-nav {
  padding: 0rem 1rem;
  /* margin-bottom: 8px; */
  /* background: var(--theme-color-main); */
  background: #175CBE;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 72;

  width:100%;
  /* box-shadow: 0 2px 4px 0 hsla(0, 0%, 80.8%, .5); */
  height: 48px;
  /* height: var(--nav-height); */
  min-height: unset;

  display: flex;
  word-break: break-word;
  /* box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15); */
}

@media screen and (max-width: 420px){
  .mtm-nav {
    padding: 0rem;
  }
}

.mtm-nav.sticky {
  position: fixed;
  /* background: var(--theme-color-main); */
  background: #fff;
}

.mtm-nav-header {
  display: flex;
  /* padding: 8px; */
  align-items: center;
  justify-content: center;
  /* margin-right: 16px; */
}

.mtm-nav-logo{
  display: inline-flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.mtm-nav-logo-image {
  height: 36px;
}

.mtm-nav-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  margin-right: 0;
}

.mtm-nav-left-section,
.mtm-nav-right-section {
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.mtm-nav-left-section > * {
  cursor: pointer;
}

/* .mtm-nav-left-section {
  gap: 1rem;
} */

.mtm-nav-right-section {
  gap: 24px;
}

.mtm-nav-login-button,
.mtm-nav-logout-button,
.mtm-nav-user-name {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1.5;
  position: relative;
  padding: 0 0.75rem;
}

.mtu-nav-menu{
  font-size: 20px
}

.mtu-nav-menu1{
  width: 84px;
  height: 48px;
  background-image: url("/static/assets/NavMenu1.svg");
  background-repeat: no-repeat;
}

.mtm-nav-user-name {
  margin-right: 32px;
}

.mtu-nav-menu:hover {
  color: #1E40AF;
}

.star1:hover{
  cursor: pointer;
}
.star1:hover path{
  stroke: #FFF500;
  stroke-width: 2;
}
.star2:hover path{
  stroke: #F6D5EA;
  stroke-width: 2;
}
.star3:hover path{
  stroke: #F17272;
  stroke-width: 2;
}