/* 데스크탑 */
@media screen and (min-width: 1281px) {
    .contact__header {
      padding: 80px 0;
    }
    .contact__logo {
      height: 72px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 55px;
  
      font-size: 38px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      padding-bottom:20px;
      line-height: 55px;
  
      font-size: 28px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title br {
      display: none;
    }
    .contact__img {
      margin-top: 40px;
      height: 121px;
    }
    .contact__body {
      width: 758px;
    }
    .contact__body .section__title {
      padding-top: 40px;
  
      line-height: 34px;
      font-size: 24px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 20px;
    }
    .section__box {
      margin-top: 20px;
      width: 100%;
    }
    .box__title {
      line-height: 34px;
      font-size: 18px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 14px 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 126px;
      height: 44px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }
  }
  
  /* 태블릿 */
  @media screen and (min-width: 744px) and (max-width: 1280px) {
    .contact__header {
      padding: 60px 0;
    }
    .contact__logo {
      height: 41px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 34px;
  
      font-size: 24px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      line-height: 32px;
  
      font-size: 22px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title br {
      display: none;
    }
    .contact__img {
      margin-top: 40px;
      height: 111px;
    }
    .contact__body {
      width: 100%;
      max-width: 758px;
    }
    .contact__body .section__title {
      padding-top: 40px;
  
      line-height: 28px;
      font-size: 20px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 20px;
    }
    .section__box {
      margin-top: 20px;
      width: 100%;
    }
    .box__title {
      line-height: 34px;
      font-size: 18px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 14px 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 126px;
      height: 44px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }
  }
  
  
  /* 모바일 */
  @media screen and (max-width: 743px) {
    .contact__header {
      padding: 60px 0;
      width: 100%;
    }
    .contact__logo {
      height: 41px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 28px;
  
      font-size: 18px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      line-height: 30px;
  
      font-size: 17px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__img {
      margin-top: 40px;
      height: 88px;
    }
  
    .contact__body {
      width: 100%;
    }
    .contact__body  .section__title {
      padding-top: 20px;
  
      line-height: 24px;
      font-size: 17px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 8px;
    }
    .section__box {
      margin-top: 12px;
      width: 100%;
    }
    .box__title {
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 11px 12px;

      border: solid 1px #d1d1d1;

  
      line-height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px 12px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 114px;
      height: 40px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }
  }