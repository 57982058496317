/* .cl-solution-text-wrapper {
    height: auto;
    transition : height 0.5 ease;
} */

.cl-solution-text-header {
    /* background-color: rgb(199, 251, 177); */
    background-color: var(--theme-color-v2-c0-rgb);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    padding: 0.25rem;
    margin-top: 0;
    margin-left: 0;
}

.cl-solution-text-header:hover {
    /* background-color: rgb(241, 251, 172); */
    background-color: var(--theme-color-v2-c0-rgb);
}

.cl-solution-text-list-btn {
    border: 2px solid white;
    color: white;
    padding: 0 0.5rem;
}

.cl-solution-text-item-divider {
    background-color: rgba(170, 170, 170, 0.3);
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    margin-right: 4px;
    height: 1px;
}

/* 스크롤바 숨기기 */
.cl-solution-text-content {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* 스크롤바 숨기기 */
.cl-solution-text-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

.cl-solution-text-wrapper.hidden .cl-solution-text-content {
    max-height: 0;
    background-color: rgba(var(--theme-color-v2-c2), 0.1);
    transition: max-height .5s ease;
}

.cl-solution-text-wrapper.open .cl-solution-text-content {
    max-height: 100vh;
    background-color: rgba(var(--theme-color-v2-c2), 0.1);
    transition: max-height 1.5s ease;
}

.cl-solution-text-content:first-child {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
}
