.mtuicon {
  display:inline-flex;
  align-items:center;
  color:inherit;
  font-style:normal;
  line-height:0;
  text-align:center;
  text-transform:none;
  vertical-align:-.125em;
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

.mtuicon > * {
  line-height: 1;
}

.mtuicon::before {
  display: none;
}

.mtuicon svg {
  display: inline-block;
}

/* Spin */
.mtuicon-spin::before,
.mtuicon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}