.form-group {
    position: relative;
}

.form-control {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus {
    outline: none !important;
    /* border:1px solid rgb(243,174,34);
    box-shadow: 0 0 10px rgb(240, 121, 79); */
    border: 1px solid var(--theme-color-v2-c0-rgb);
    box-shadow: 0 0 10px var(--theme-color-v2-c0-rgb);
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.form-check-label {
    margin-bottom: 0;
}

.mtm-form {
    background-color: rgba(var(--theme-color-v2-c0),0.3);
    border-radius: 0 0 5px 5px;
}