.mtm-analog-clock {
    /* position: relative;
    width: 240px;
    height: 240px; */
    position: absolute;
    display: block;
    left: 50%;
    transform: translate(-50%, 0);
    width: 240px;
    height: 240px;
    /* padding: 20px; */
    box-sizing: border-box;
}
  
.mtm-analog-clock::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background: var(--theme-color-v2-c1-rgb);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
}
  
.mtm-analog-clock > * {
    width: 100%;
    height: 100%;
}
  
.mtm-analog-clock-face {
    position: relative;
    border: 10px solid var(--theme-color-v2-c1-rgb);
    border-radius: 50%;
    background: rgba(var(--theme-color-v2-c1), 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
    z-index: 1;
}
  
.mtm-analog-clock-line-circle {
    width: 100%;
    height: 100%;
    border: 1px solid var(--theme-color-v2-c1-rgb);
    box-sizing: border-box;
    border-radius: 50%;
}
  
.mtm-analog-clock-line-circle .mtm-analog-clock-line {
    position: absolute;
    width: 220px;
    background: var(--theme-color-v2-c1-rgb);
}
  
.mtm-analog-clock-line-circle .mtm-analog-clock-line.thick {
    top: calc(50% - 2px);
    height: 3px;
    z-index: 1;
}
  
.mtm-analog-clock-line-circle .mtm-analog-clock-line.thin {
    top: calc(50% - 0.5px);
    height: 1px;
}
  
.mtm-analog-clock-board-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    height: 95%;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
  
.mtm-analog-clock-name {
    position: absolute;
    top: 65%;
    left: 50%;
    font-size: 8px;
    font-weight: 900;
    z-index: 10;
    color : var(--theme-color-v2-c1-rgb);
    transform: translate(-50%, 0);
}
  
.mtm-analog-clock-number-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
  
.mtm-analog-clock-numbers {
    position: absolute;
    top: calc(50% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    transform-origin: center 30px;
}
  
.mtm-analog-clock-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
}
  
.mtm-analog-clock-time {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
}
  
.mtm-analog-clock-hour-hand {
    z-index: 2;
}
  
.mtm-analog-clock-hour-hand::after {
    content: '';
    position: absolute;
    display: block;
    top: 30%;
    left: 50%;
    width: 5px;
    height: 25%;
    background: #000;
    transform: translateX(-50%);
}
  
.mtm-analog-clock-minute-hand {
    z-index: 3;
}
  
.mtm-analog-clock-minute-hand::after {
    content: '';
    position: absolute;
    display: block;
    top: 23%;
    left: 50%;
    width: 3px;
    height: 32%;
    background: #000;
    transform: translateX(-50%);
}
  
.mtm-analog-clock-second-hand {
    z-index: 4;
}
  
.mtm-analog-clock-second-hand::after {
    content: '';
    position: absolute;
    display: block;
    top: 20%;
    left: calc(50% - 1px);
    width: 2px;
    height: 40%;
    background: var(--theme-color-v2-c1-rgb);
}