.mtu-btn {
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  /* line height 어떻게 두지*/
  /* line-height: 1.57; */
  color: rgba(0, 0, 0, 0.88);

  /* size? */
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
}

.mtu-btn > span {
  display: inline-block;
}

/* Type */
.mtu-btn-default {
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02)
}

.mtu-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.mtu-btn-dashed {
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-style: dashed;
}

.mtu-btn-link {
  color: #1677ff;
}

/* Shape */
.mtu-btn-circle {
  min-width: 32px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
}

.mtu-btn-round {
  border-radius: 32px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  width: auto; /*이거 써야되나?*/
}

/* Size */
.mtu-btn-large {
  font-size: 16px;
  height: 40px;
  padding: 6.4px 15px; /* Fix */
  border-radius: 8px;
}

.mtu-btn-small {
  font-size: 14px;
  height: 24px;
  padding: 0 7px;
  border-radius: 4px;
}

/* disabled */
.mtu-btn-default:disabled,
.mtu-btn-primary:disabled,
.mtu-btn-dashed:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

.mtu-btn-text:disabled,
.mtu-btn-link:disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

/* hover */
.mtu-btn-primary:not(:disabled):hover {
  color: #fff;
  background-color: #4096ff;
}

.mtu-btn-default:not(:disabled):hover,
.mtu-btn-dashed:not(:disabled):hover {
  color: #4096ff;
  border-color: #4096ff;
}

.mtu-btn-text:not(:disabled):hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: rgba(0, 0, 0, 0.06);
}

.mtu-btn-link:not(:disabled):hover {
  color: #69b1ff;
  background-color: transparent;
}