.mtm-player-video-content-flexbox {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  justify-content: center;
}

.mtm-player-video-content-flexbox>* {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.mtm-player-video-content-wrapper {
  justify-content: center;
  flex: 0 0 auto;
  width: 100%;
  padding: 0.5rem 1.5rem;
}


@media (min-width: 768px) {
  .mtm-player-video-content-wrapper {
    flex: 0 0 auto;
    /* width: 83.333%; */
  }
}

@media (min-width: 992px) {
  .mtm-player-video-content-wrapper {
    flex: 0 0 auto;
    /* width: 75%; */
  }
}

@media (min-width: 1200px) {
  .mtm-player-video-content-wrapper {
    flex: 0 0 auto;
    /* width: 66.666%; */
  }
}