.mtm-study-submit-action-hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
  margin-bottom: 0.25rem;
}

.mtm-study-submit-action-button-container {
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}