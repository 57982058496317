html,
body {
  font-family: "Noto Sans KR", sans-serif;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  word-break: keep-all;
}

.landing-swiper {
  width: 100%;
  height: 100%;
}


/* 데스크탑 */
@media screen and (min-width: 1281px) {
  .banner-mobile {
    display: none;
  }
  .categoryMobile {
    width: fit-content;
    font-size: 18px;
    column-gap: 30px;
  }
  .contentGrid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .category-click-border {
    padding: 12px 16px;
  }
  .logoImg {
    height: 48px;
  }

  .noticePC {
    display:block;
  }
  .noticeMobile {
    display:none;
  }

  .headerSize {
    margin-top: 80px;
  }
}

/* 태블릿 */
@media screen and (min-width: 744px) and (max-width: 1280px) {
  .banner-mobile {
    display: none;
  }
  .contentGrid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .categoryMobile {
    width: fit-content;
    font-size: 16px;
  }
  .category-click-border {
    padding: 12px 16px;
  }
  .logoImg {
    height: 40px;
  }

  .noticePC {
    display:block;
  }
  .noticeMobile {
    display:none;
  }

  .headerSize {
    margin-top: 60px;
  }
}


/* 모바일 */
@media screen and (max-width: 743px) {
  .banner-web {
    display: none;
  }
  .banner-mobile {
    margin-top: 46px;
  }
  .contentGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    place-items: center;
  }
  .categoryMobile {
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid #d1d1d1;
    padding: 0 20px;
  }
  .category-click-border {
    padding: 10px 0;
  }
  .logoImg {
    height: 30px;
  }

  .noticePC {
    display:none;
  }
  .noticeMobile {
    display:block;
  }

  .thumnailMobile {
    height: 140px;
  }
  .headerSize {
    margin-top: 46px;
  }
}
