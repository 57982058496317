.mtm-exam-information-card-flexbox {
  --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));

    justify-content: center;
    margin-top: 0.5rem;
}

.mtm-exam-information-card-flexbox > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.mtm-exam-information-card-wrapper {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .mtm-exam-information-card-wrapper {
    flex: 0 0 auto;
    width: 83.333%;
  }
}

@media (min-width: 992px) {
  .mtm-exam-information-card-wrapper {
    flex: 0 0 auto;
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .mtm-exam-information-card-wrapper {
    flex: 0 0 auto;
    width: 66.666%;
  }
}

.mtm-exam-information-card-wrapper .mtm-testum-card-normal {
  margin: 0.25rem;
}

.mtm-exam-information-card-wrapper .mtm-testum-card-normal-body {
  padding: 0.25rem;
}

/* title area */
.mtm-testum-card-normal-title-area {
  padding: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
}

.mtm-testum-card-normal-title-area-title {
  display: flex;
  justify-content: center;
}

/* divider (hr) */
.mtm-exam-information-card-divider-top {
  margin-top: 0.25rem;
}

.mtm-exam-information-card-content-area {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.mtm-exam-information-card-normal-normal {
  padding: 0 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

/* input area */
.mtm-exam-information-card-input-area {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));

  display: flex;
  justify-content: center;
  padding: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
}

.mtm-exam-information-card-input-area > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.mtm-exam-information-card-description-area {
  display: flex;
  justify-content: center;
}

.mtm-exam-information-card-action-area {
  display: flex;
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}