.mtm-digital-clock {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
}

.mtm-digital-clock h2 {
    color: var(--theme-color-v2-c1-rgb);
    position: relative;
    display: block;
    text-align: center;
    margin: 10px 0;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.4em;
    font-size: 0.8em;
}

.mtm-digital-clock .time {
    display: flex;
}

.mtm-digital-clock .time div {
    position: relative;
    margin: 0 5px;
    -webkit-box-reflect: below 10px linear-gradient(transparent, #0004);
}

.mtm-digital-clock .time div span {
    position: realtive;
    display: block;
    background: #98d321;
    width: 100px;
    height: 80px;
    /* color: black; */
    color : white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    z-index: 10;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
}

.mtm-digital-clock .time div span:nth-child(2) {
    height: 30px;
    color: black;
    font-size: 1.0em;
    letter-spacing: 0.2em;
    z-index: 9;
    box-shadow: none;
    background: #127fd6;
    Text-transform: uppercase;
}

.mtm-digital-clock .time div:nth-child(1) span {
    background: #98d321;
}

.mtm-digital-clock .time div:nth-child(1) span:nth-child(2) {
    background: #7aaa1a;
}

.mtm-digital-clock .time div:nth-child(2) span {
    background: #2196d3;
}

.mtm-digital-clock .time div:nth-child(2) span:nth-child(2) {
    background: #127fd6;
}

.mtm-digital-clock .time div:last-child span {
    background: #ff006a;
}

.mtm-digital-clock .time div:last-child span:nth-child(2) {
    background: #ec0062;
}