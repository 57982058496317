.mtm-study-message-popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    /* background-color: rgba(94, 110, 141, 0.9); */
    background-color: rgba(60, 67, 79, 0.8);

    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.mtm-study-message-popup.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
}

.mtm-study-message-popup-container {
    position: relative;
    width: 90%;
    max-width: 460px;
    /* margin: 4em auto; */
    margin: 200px auto auto 880px;
    background: #FFF;
    border-radius: .25em .25em .4em .4em;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    
    display: flex;
    justify-content: center;
}

/* card-normal */
.mtm-study-message-popup-card {
    margin: 0.25rem;
}

.mtm-study-message-popup-card-body {
    padding: 0.25rem;
}

.mtm-study-message-popup-card-body-title {
    display: flex;
    justify-content: center;
    padding: 0.25rem;
    margin-top: 0;
    margin-bottom: 0;
}

.mtm-study-message-popup-card-divider {
    margin-top: 0.25rem;
}

.mtm-study-message-popup-card-body-message {
    display: flex;
    justify-content: center;
    padding: 0.25rem;
    margin-top: 0;
    margin-bottom: 0;
}

.img-replace {
    /* replace text with an image */
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;
}

.mtm-study-message-popup-container .mtm-study-message-popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
}

.mtm-study-message-popup-container .mtm-study-message-popup-close::before,
.mtm-study-message-popup-container .mtm-study-message-popup-close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 14px;
    height: 3px;
    background-color: #8f9cb5;
}

.mtm-study-message-popup-container .mtm-study-message-popup-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
}

.mtm-study-message-popup-container .mtm-study-message-popup-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 8px;
}

.is-visible .mtm-study-message-popup-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
