.btn-expand-list {
    display: flex;
}

@media (max-width: 820px){
  .btn-expand-list {
      display: none;
  }
}

.btn-expand {
    width: 110px;
    height:36px;
    margin: 2px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    border-radius: 10px;
    background-color: #F17272;

    cursor: pointer;
}

.btn-expand:hover {
    /* align-items: center; */
    width: 110px;
}

.close {
    width: fit-content;
}

.close p {
    display: none;
}

.btn-expand p {
    color: white;
    font-size: 12px;
    padding-left: 0.2rem;
    word-break: keep-all;
}



.btn-expand:hover p {
    display: block;
    color: white;
    font-size: 12px;
}