.swiper-slide{
    
    font-size: 18px;
	background-color: transparent;
    background: transparent;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
    /* border-radius: 100%; */
}

.mtm-player-indicator {
    box-sizing: border-box;
    width: 30px;
    height: 30px;

    /* color: #fff;
    background: #1c98f0; */
    color : rgb(190, 208, 232);
    background: #fff;

    border-radius: 100%;
    border: 2px solid rgb(190, 208, 232);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
}

.mtm-player-indicator.active{

    color : black;
    font-weight: 600;
    border: 3px solid rgba(46, 107, 174);

}

.mtm-player-testum-indicator .swiper-container{
    background-color: var(--theme-color-deeplight);
}